import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import ExternalLink from '../components/ExternalLink';

export const frontmatter = {
  title: 'Impressum',
  footer: 1,
};

export default () => (
  <Layout>
    <Helmet>
      <title>Impressum</title>
    </Helmet>
    <h1>Impressum</h1>
    <p>
      <b>Hirsch-Apotheke Korbach</b>
    </p>
    <p>
      Prof.-Kümmell-Straße 2<br />
      34497 Korbach
    </p>
    <p>
      Tel: 05631 / 25 45
      <br />
      Fax: 05631 / 913 923
      <br />
      E-Mail: kontakt@hirsch-apotheke-korbach.de
    </p>
    <h3>Inhaberin</h3>
    <p>
      Pia Ueberson e. K.
      <br />
      <b>Berufsbezeichnung:</b> Apotheker
      <br />
      <b>Approbation erworben in:</b> Deutschland
    </p>
    <h3>Aufsichtsbehörde</h3>
    <p>
      <b>Regierungspräsidium Darmstadt</b>
      <br />
      Luisenplatz 2<br />
      64283 Darmstadt
      <br />
      Tel.: 06151-120
      <br />
    </p>
    {/* TODO: add new tax number when available */}
    {/* <h3>Handelsregister</h3>
    <p>
      <b>Registergericht:</b> Amtsgericht Korbach
      <br />
      <b>Registernummer:</b> HRA 176 <br />
      <b>USt-IdNr:</b> DE 183 944 871
    </p> */}
    <h3>Zuständige Apothekerkammer</h3>
    <p>
      <b>Landesapothekerkammer Hessen</b>
      <br />
      Lise-Meitner-Straße 4
      <br />
      60486 Frankfurt am Main
      <br />
      Tel. 069-979509-0
      <br />
      <ExternalLink href="http://www.apothekerkammer.de/">
        {' '}
        www.apothekerkammer.de
      </ExternalLink>
    </p>
    <p>
      <b>Berufsregelungen:</b>
      <br />
      <ExternalLink href="http://www.apothekerkammer.de/recht/rechtsgrundlagen/">
        Rechtsgrundlagen der Landesapothekerkammer Hessen
      </ExternalLink>
    </p>
    <h3>Berufshaftplichtversicherung</h3>
    <p>Württembergische Versicherung AG</p>
    <h3>Streitbeilegung</h3>
    <p>
      Für außergerichtliche Beilegungen von verbraucherrechtlichen
      Streitigkeiten hat die Europäische Union eine Onlineplattform
      („OS-Plattform”) eingerichtet, an die Sie sich demnächst wenden können.
      Die Plattform finden Sie unter{' '}
      <ExternalLink href="https://ec.europa.eu/consumers/odr/">
        https://ec.europa.eu/consumers/odr/
      </ExternalLink>
    </p>
    <h4>Information nach dem Verbraucherstreitbeilegungsgesetz (VSBG)</h4>
    <p>
      Wir sind nicht bereit und nicht verpflichtet, an Streitbeilegungsverfahren
      vor einer Verbraucherschlichtungsstelle im Sinne des
      Verbraucherstreitbeilegungsgesetzes teilzunehmen.
    </p>
  </Layout>
);
